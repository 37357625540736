/* You can add global styles to this file, and also import other style files */

@import "../node_modules/toastr/build/toastr.min.css";
@import "../node_modules/element.ui/modules/all.scss";



$primary: $color-mayo-50;
$danger: $color-red-90;
$success: $color-green-70;
$light: $color-mayo-10;
$dark: $color-grey-90;
$secondary: #CCC;
$info: $color-mayo-20;
$warning: $color-orange-50;
$light: $color-mayo-10;
$dark: $color-grey-90;
/*$font-size-base: 16px !default; // Assumes the browser default, typically `16px`
$line-height-base: 22px !default;*/


$theme-colors: ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark );


$card-cap-bg: $color-mayo-50;
$card-border-color: $color-mayo-40;

$nav-tabs-link-active-color: $color-mayo-50;
$nav-tabs-link-active-bg: $color-mayo-50;
$nav-tabs-link-active-border-color: $color-mayo-50;

$navbar-light-toggler-bg: $color-mayo-50;
$navbar-light-color: rgba($color-mayo-50, .5) !default;
$navbar-light-hover-color: rgba($color-mayo-50, .7) !default;
$navbar-light-active-color: rgba($color-mayo-50, .9) !default;
$navbar-light-disabled-color: rgba($color-mayo-50, .3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($color-mayo-50, .1) !default;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Helvetica Arial, sans-serif, !default;
$enable-shadows: true;

$list-group-action-color: $color-mayo-5;
$list-group-hover-bg: $color-mayo-50;
$list-group-action-active-color: $color-mayo-5;
$list-group-action-active-bg: $color-mayo-40;
$list-group-border-color: #CCC;


@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fa-font-path: "../node_modules/font-awesome/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";


/* ELEMENT STYLES */
body {
    position: relative;
    height: 100%;
    line-height: 22px;
    font-size: 12pt;
}

dd, dt {
    line-height: 1.25;
}

dt {
    font-weight: 500;
}

table thead th,.table thead th {
    background: #fff;
    color: #54585a;
    font-weight: 700;
    border-bottom: 6px solid #babbb1;
    text-align: left;
    padding: 12px 12px;
}

.checkbox-option, .radio-option {
    cursor: pointer;
    display: inline-flex;
}

input[type=checkbox], input[type=radio] {
    padding: .5em;
}
/* NEED TO OVERWRITE ELEMENT UI */
h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-sans-serif;
}

h1 {
    font-size: 1.6em;
    line-height: 48px;
    font-weight: bold
}

/*h2,   {
  font-size: 1.5em;
  line-height: 36px
}*/

h2, h3 {
    font-size: 1.4em;
    line-height: 34px;
    font-weight: bold
}

h4 {
    font-size: 1.3em;
    line-height: 28px;
    font-weight: bold
}

h5 {
    font-size: 1.2em;
    line-height: 22px;
    font-weight: bold
}


.card {
    border: 0px none #99B1DB;

}

#m-header {
    margin-bottom:50px;
    margin-top:50px;
}

#m-main > section, #m-content-exchange > section {
    margin-bottom: 40px;
}

#m-content-meta {
    display:none;
}

.m-content, .m-section {
    padding:1em;
}

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

/*
Forces carousel image to be 100% width and not max width of 100%
*/
.carousel-item .img-fluid {
    width: 100%;
    height: 100%;
}

.carousel-caption {
    position: relative;
    left: 0;
    top: 0;
}

.m-popup-image-thumb, .m-popup-image-thumb-cap {
    display:none;
}

label, legend {
    font-size: 1em;
    font-weight: 500;
}
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px $color-mayo-50;
}

/* BOOTSTRAP OVERIDES */
a.nav-link, a.navbar-brand, a.navbar-brand span.application-title {
    font-size: 1.5rem;
}

a.navbar-brand span {
    font-size: 2.5rem;
    text-transform: uppercase;
}

a.navbar-brand img {
    height: 100px;
}

.btn {
    text-transform: uppercase;
}

.mayo .navbar-toggler-icon {
    color: $color-mayo-50;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,61,165, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.mayo.navbar-toggler {
    border-color: $color-mayo-50;
}

.mayo.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.card-img-container {
    background-color: $color-mayo-50;
    border: 1px solid $color-mayo-50;
    border-radius: .25rem;
    margin: map-get($spacers, 3);
}

a.card-img-container, a.card-img-container:hover {
    text-decoration: none;
}

.card-img-top {
    height: 200px;
}

svg.card-img-top {
    fill: #FFF;
    background-color: $color-mayo-50;
    padding-bottom: 10px;
    padding-top: 10px;
}


svg.social-icons {
    fill: $color-mayo-20;
    background-color: $color-grey-90;
    height: 25px;
    width: 25px;
}

.card-img-append {
    color: $white;
    background-color: $color-mayo-50;
    padding-bottom: map-get($spacers, 5);
    padding-top: map-get($spacers, 5);
}

.card-header {
    color: $white;
    border-bottom: 1px $color-mayo-50;
    font-size: 1.25rem;
}

.card-footer {
    background-color: $color-mayo-40;
}



[data-toggle="collapse"] .fa {
    color: $white;
}

[data-toggle="collapse"] .fa:before {
    content: "\f077";
}

[data-toggle="collapse"].collapsed .fa:before {
    content: "\f078";
}

/* UI ELEMENT OVVERIDES */
a.mcui-btn {
    display: inline-block;
}

/* LAYOUT (HEADER FOOTER) */
.header {
    background-color: $white !important;
}

.footer {
    min-height: 60px;
    background-color: $color-grey-90;
    color: $white;
}

.footer a {
    color: $white;
}

/* CUSTOM CLASSES HERE */
.flex-container {
    display: flex;
    flex-direction: row;
}


a.nav-secondary-link {
    color: $color-grey-90;
    font-size: 20px;
}

a.nav-secondary-link svg {
    color: $color-mayo-50;
    width: 23px;
    height: 23px;
}

.table .thead-mayo th {
    color: $white;
    background-color: $color-mayo-50;
    border-color: $color-mayo-50;
}

.back-to-top {
    background: none;
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 70px;
    height: 70px;
    z-index: 100;
    display: none;
    text-decoration: none;
    opacity: .7;
}

.back-to-top i {
    font-size: 60px;
    color: $color-mayo-40;
}

.affix {
    position: fixed;
    z-index: 1030;
    top: 0;
}


.print-container {
    display: flex;
    justify-content: flex-end;
}

@media print {
    .noprint {
        visibility: hidden;
    }
}


.mc-button.tertiary {
    align-items: center;
    color: #0057b8;
    background: none;
    border: 0;
    display: flex;
    font-size: 16px;
    gap: 8px;
    line-height: 24px;
    text-decoration: underline;
}

.mc-button.tertiary:hover {
    color: #1371d5;
    cursor: pointer;
}

.mc-button.tertiary:active {
    color: #002443;
}

.mc-button.tertiary:focus-visible {
    outline: 2px dashed #0057b8;
    outline-offset: 2px;
}

.mc-icon {
    block-size: 24px;
    inline-size: 24px;
}

#kaltura_player_dynamic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* MEDIA QUERIES */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    /* Styles */
    .page-title {
        font-size: 1em;
    }

    a.navbar-brand span.application-title {
        font-size: 1rem;
    }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
    /* Styles */

}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 320px) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }

    article.p-5 {
        padding: 0rem !important;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    /* Styles */

}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
}

/* iPad 3 --------------------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}
/*  iPad Pro 9.7"----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
/*iPad Pro 10.5"*/
/* Portrait */
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}
/*ipad Pro 12.9"*/
/* Portrait */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}

/* Landscape */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    /* Styles */
}



/* Desktops and laptops ----------- */
@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-collapse .application-title, .navbar-expand-xl .navbar-collapse .display-screen {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-brand span.application-title {
        display: none;
    }

    .navbar-expand-xl .navbar-collapse .display-mobile {
        display: none;
    }
}

@media (max-width: 1200px) {
    .navbar-expand-xl .navbar-collapse .application-title, .navbar-expand-xl .navbar-collapse .display-screen {
        display: none;
    }

    .navbar-brand span.application-title {
        display: inline-flex;
    }

    a.nav-link {
        font-size: 16px;
    }

    .navbar-expand-xl .navbar-collapse .display-mobile {
        display: flex !important;
        flex-basis: auto;
    }
}

@media only screen and (min-width: 992px) {
    #m-content-exchange {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media only screen and (min-width: 768px) {
    #m-content-exchange {
        flex: 0 0 50%;
        max-width: 50%;
    }
}


@media only screen and (min-width : 1224px) {
    /* Styles */
    /*#list-articles {
        position: fixed;
    }*/
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
    /* Styles */
    /*#list-articles {
        position:fixed;
    }*/
}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
}

/* iPhone 5 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: .75rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone 6, 7, 8 ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1.25rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
    a.navbar-brand span.application-title {
        font-size: 1rem;
    }

    a.navbar-brand img {
        height: 80px;
    }
}

/* Samsung Galaxy S3 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    /* Styles */
}

/* Samsung Galaxy S4 ----------- */
@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

/* Samsung Galaxy S5 ----------- */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}

@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    /* Styles */
}
